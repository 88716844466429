import React, { useContext } from 'react';
import styled from 'styled-components'
import {BRANDS} from "../config/brands";

//import { AppContext } from '../context/AppContext'
import { ThemeContext } from '../context/ThemeContext'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 60px;
    min-height: 60px;
    background-color: ${props => props.bg};
    box-shadow: 0 2px 4px 0 rgba(209,209,209,0.50);
    font-weight: bold;
    font-size: 20px;
    a {
        display: inherit;
    }
    img{
        max-height: 40px;
        margin: 0px;
        padding: 0px;
    }
`

const Header = () => {
  const {value: themeState} = useContext(ThemeContext);

  //const brand = sessionStorage.getItem('brand');
  //const logo = BRANDS[brand]?.logo || BRANDS['DEFAULT']?.logo;

  return (
    <Wrapper bg={themeState?.header}>
      <img src={BRANDS.DEFAULT.logo} alt=""/>
    </Wrapper>)
}
export default Header
